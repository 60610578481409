import React from 'react';
import { HomeHero } from '@/components';

const IndexPage = () => {
  return (
    <>
      <HomeHero />
      <section className="bg-gray-900 position-relative">
        <div className="container">
          <div className="row g-0">
            <div className="col-12">
              <div className="p-6 bg-white floating-box">
                <h2 className="mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h2>
              </div>
            </div>
            <div className="col-4">
              <div className="p-6 bg-white">
                <h3 className="mb-5">Кои сме ние</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum
                  tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero
                  vitae erat.
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="p-6 bg-white">
                <h3 className="mb-5">Какво предлагаме</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum
                  tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero
                  vitae erat.
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="p-6 bg-white">
                <h3 className="mb-5">Как работим</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum
                  tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero
                  vitae erat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-900 pt-8 pb-10 text-white text-center">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-7 h4">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h2>
            </div>
            <div className="col-4">
              <div className="display-3 mb-3">25+</div>
              <div className="lead">Awarded Projects</div>
            </div>
            <div className="col-4">
              <div className="display-3 mb-3">25+</div>
              <div className="lead">Awarded Projects</div>
            </div>
            <div className="col-4">
              <div className="display-3 mb-3">25+</div>
              <div className="lead">Awarded Projects</div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-200 py-10 text-center">
        <div className="container-fluid container-fluid--custom ">
          <div className="row justify-content-center">
            <div className="col-11">
              <h2 className="h1 mb-3">Кухни</h2>
              <p className="lead mb-6">Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
              <div className="row">
                <div className="col-4 mb-3">
                  <div className="card mb-3">
                    <img src="https://picsum.photos/1120/630" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <div className="row text-start">
                        <div className="col-6">
                          <div className="fw-bold">Размери:</div>
                          10 x 10 x 10
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Материали:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Механизми:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Срок на изработка:</div>2 месца - Юни, 2002
                        </div>
                        <div className="col-6">
                          Крайна цена: <span className="fw-bold">4000 лв</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <div className="card mb-3">
                    <img src="https://picsum.photos/1120/630" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <div className="row text-start">
                        <div className="col-6">
                          <div className="fw-bold">Размери:</div>
                          10 x 10 x 10
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Материали:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Механизми:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Срок на изработка:</div>2 месца - Юни, 2002
                        </div>
                        <div className="col-6">
                          Крайна цена: <span className="fw-bold">4000 лв</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <div className="card mb-3">
                    <img src="https://picsum.photos/1120/630" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <div className="row text-start">
                        <div className="col-6">
                          <div className="fw-bold">Размери:</div>
                          10 x 10 x 10
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Материали:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Механизми:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Срок на изработка:</div>2 месца - Юни, 2002
                        </div>
                        <div className="col-6">
                          Крайна цена: <span className="fw-bold">4000 лв</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-900 py-10 text-center">
        <div className="container-fluid container-fluid--custom ">
          <div className="row justify-content-center">
            <div className="col-11">
              <h2 className="h1 mb-3 text-white">Мебели</h2>
              <p className="lead mb-6 text-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
              <div className="row">
                <div className="col-4 mb-3">
                  <div className="card mb-3">
                    <img src="https://picsum.photos/1120/630" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <div className="row text-start">
                        <div className="col-6">
                          <div className="fw-bold">Размери:</div>
                          10 x 10 x 10
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Материали:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Механизми:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Срок на изработка:</div>2 месца - Юни, 2002
                        </div>
                        <div className="col-6">
                          Крайна цена: <span className="fw-bold">4000 лв</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <div className="card mb-3">
                    <img src="https://picsum.photos/1120/630" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <div className="row text-start">
                        <div className="col-6">
                          <div className="fw-bold">Размери:</div>
                          10 x 10 x 10
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Материали:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Механизми:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Срок на изработка:</div>2 месца - Юни, 2002
                        </div>
                        <div className="col-6">
                          Крайна цена: <span className="fw-bold">4000 лв</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <div className="card mb-3">
                    <img src="https://picsum.photos/1120/630" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <div className="row text-start">
                        <div className="col-6">
                          <div className="fw-bold">Размери:</div>
                          10 x 10 x 10
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Материали:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Механизми:</div> -
                        </div>
                        <div className="col-6">
                          <div className="fw-bold">Срок на изработка:</div>2 месца - Юни, 2002
                        </div>
                        <div className="col-6">
                          Крайна цена: <span className="fw-bold">4000 лв</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-gray-700">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="home-brands d-flex align-items-center justify-content-center">
                <img
                  src="https://assets.website-files.com/635a92eeb0865498142125f2/635bd23cc5ea0dc2f7d19a00_file%20(4).svg"
                  alt=""
                  height="200"
                  id="w-node-_472e2375-1851-3d6e-e404-1c2afc0588fd-cf2a25c0"
                />
                <img
                  src="https://assets.website-files.com/63c7fbbb0cb4c40a57b7d929/63d06c421819e0a42158772a_file%20(3).svg"
                  id="w-node-_472e2375-1851-3d6e-e404-1c2afc0588fe-cf2a25c0"
                  alt=""
                />
                <img
                  src="https://assets.website-files.com/635a92eeb0865498142125f2/635bd23cc5ea0d3bd3d199fe_file.svg"
                  id="w-node-_472e2375-1851-3d6e-e404-1c2afc0588ff-cf2a25c0"
                  alt=""
                />
                <img
                  src="https://assets.website-files.com/635a92eeb0865498142125f2/635bd23cc5ea0d1767d19a02_file%20(5).svg"
                  id="w-node-_472e2375-1851-3d6e-e404-1c2afc058900-cf2a25c0"
                  alt=""
                />
                <img
                  src="https://assets.website-files.com/63c7fbbb0cb4c40a57b7d929/63d06c421819e09448587728_file%20(1).svg"
                  id="w-node-_472e2375-1851-3d6e-e404-1c2afc058901-cf2a25c0"
                  alt=""
                />
                <img
                  src="https://assets.website-files.com/63c7fbbb0cb4c40a57b7d929/63d06c421819e05d87587729_file%20(6).svg"
                  id="w-node-_472e2375-1851-3d6e-e404-1c2afc058902-cf2a25c0"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="container py-10">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="h1 mb-3">Клиентите за нас</h2>
            <p className="lead mb-6">Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
          </div>
          <div className="col-4">
            <div className="avatar text-center">
              <img src="https://i.pravatar.cc/130" className="rounded-circle" alt="" />
              <h5 className="mt-3 mb-2">Бати Сашко</h5>
              <div className="mb-2">⭐ ⭐ ⭐ ⭐ ⭐</div>
              <div className="small">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi delectus dolorem expedita illum
                incidunt necessitatibus sed temporibus velit. Consequuntur cumque debitis deserunt dolor enim error id
                impedit, nihil sint veritatis.
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="avatar text-center">
              <img src="https://i.pravatar.cc/130" className="rounded-circle" alt="" />
              <h5 className="mt-3 mb-2">Бати Сашко</h5>
              <div className="mb-2">⭐ ⭐ ⭐ ⭐ ⭐</div>
              <div className="small">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi delectus dolorem expedita illum
                incidunt necessitatibus sed temporibus velit. Consequuntur cumque debitis deserunt dolor enim error id
                impedit, nihil sint veritatis.
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="avatar text-center">
              <img src="https://i.pravatar.cc/130" className="rounded-circle" alt="" />
              <h5 className="mt-3 mb-2">Бати Сашко</h5>
              <div className="mb-2">⭐ ⭐ ⭐ ⭐ ⭐</div>
              <div className="small">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi delectus dolorem expedita illum
                incidunt necessitatibus sed temporibus velit. Consequuntur cumque debitis deserunt dolor enim error id
                impedit, nihil sint veritatis.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 bg-gray-900 text-center text-white">
        <div className="container-fluid container-fluid--custom">
          <div className="row justify-content-center">
            <div className="col-6">
              <h2 className="h1 mb-3">Контакти</h2>
              <p className="lead mb-6">Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const Head = () => {
  return (
    <div>
      <title>Some title</title>
      <meta name="description" content={'some description'} />
    </div>
  );
};

export default IndexPage;
